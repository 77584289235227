import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { buildAdaptiveContentAdapter } from '../../adapter/adaptiveContent/adaptiveContent.adapter';
import { buildEditorService } from '../../services/editor/editor.service';
import { buildAdaptiveContentService } from '../../services/adaptiveContent/adaptiveContent.service';
import { handleSetConditionsClick } from './utils';
import { KB_HELP_ID } from '../../constants';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  const { httpClient } = flowAPI;
  const adaptiveContentAdapter = buildAdaptiveContentAdapter(httpClient);
  const editorService = buildEditorService(editorSDK);
  const adaptiveContentService = buildAdaptiveContentService(
    adaptiveContentAdapter,
  );

  widgetBuilder.set({
    displayName: 'Adaptive Content Widget',
    nickname: 'AdaptiveContentWidget',
  });

  return widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: 'Set Conditions',
      onClick: async () => {
        return handleSetConditionsClick(adaptiveContentService, editorService);
      },
    })
    .set('mainAction2', {
      behavior: 'HIDE',
    })
    .set('design', {
      behavior: 'HIDE',
    })
    .set('animation', {
      behavior: 'HIDE',
    })
    .set('stretch', {
      behavior: 'HIDE',
    })
    .set('settings', {
      behavior: 'HIDE',
    })
    .set('add', {
      behavior: 'HIDE',
    })
    .set('crop', {
      behavior: 'HIDE',
    })
    .set('filters', {
      behavior: 'HIDE',
    })
    .set('link', {
      behavior: 'HIDE',
    })
    .set('textLink', {
      behavior: 'HIDE',
    })
    .set('presets', {
      behavior: 'HIDE',
    })
    .set('layout', {
      behavior: 'HIDE',
    })
    .set('upgrade', {
      behavior: 'HIDE',
    })
    .set('connect', {
      behavior: 'HIDE',
    })
    .set('pinMode', {
      behavior: 'HIDE',
    })
    .set('focalPoint', {
      behavior: 'HIDE',
    })
    .set('widgetPlugins', {
      behavior: 'HIDE',
    })
    .set('help', {
      id: KB_HELP_ID,
    });
};
