import { IEditorService } from '../../services/editor/types';
import { IAdaptiveContentService } from '../../services/adaptiveContent/types';
import {
  ADAPTIVE_CONTENT_FUNCTION_TYPE_ID,
  ADAPTIVE_CONTENT_APP_DEF_ID,
} from '../../constants';
import { runWithRetry } from '../../utils/runWithRetry';

const openBusinessManagerForFunction = async (
  editorService: IEditorService,
  functionExternalId: string,
  isFunctionExist: boolean,
  originalContent: string,
): Promise<void> => {
  const encodedOriginalContent = encodeURIComponent(
    JSON.stringify({ originalContent: originalContent.slice(0, 500) }),
  );

  if (!isFunctionExist) {
    const stringifySpiConfigOverride = encodeURIComponent(
      JSON.stringify({ implementerConfigId: functionExternalId }),
    );

    const templateFunctionUrl = `/functions/manage/${ADAPTIVE_CONTENT_FUNCTION_TYPE_ID}?spiConfigOverride=${stringifySpiConfigOverride}&appDefId=${ADAPTIVE_CONTENT_APP_DEF_ID}&originalContent=${encodedOriginalContent}`;
    await editorService.openBusinessManager(templateFunctionUrl);
  } else {
    const url = `/functions/edit/${functionExternalId}?appDefId=${ADAPTIVE_CONTENT_APP_DEF_ID}&referralInfo=adaptive-content-widget&originalContent=${encodedOriginalContent}`;
    await editorService.openBusinessManager(url);
  }
};

export const handleSetConditionsClick = async (
  adaptiveContentService: IAdaptiveContentService,
  editorService: IEditorService,
) => {
  try {
    const result = await runWithRetry(
      async () => {
        const component = await editorService.getFirstSelectedComponent();
        const componentText = await editorService.getSelectedComponentText();
        if (!component) {
          throw new Error('Failed to get selected component');
        }

        const functionExternalIdResult =
          await adaptiveContentService.getFunctionExternalId(component.id);

        return {
          functionExternalIdResult,
          componentText,
        };
      },
      { attempts: 10, delayInMs: 500 },
    );

    if (!result) {
      return editorService.showError();
    }

    return await openBusinessManagerForFunction(
      editorService,
      result.functionExternalIdResult.externalId,
      result.functionExternalIdResult.isExist,
      result.componentText,
    );
  } catch (error) {
    return editorService.showError();
  }
};

export function extractParagraphText(html: string): string {
  if (!html) {
    return '';
  }
  // Remove all HTML tags and decode common entities
  return html
    .replace(/<[^>]*>/g, '')
    .replace(/&nbsp;/g, ' ')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&')
    .replace(/&quot;/g, '"')
    .trim();
}
