import { EditorReadyFn, GetAppManifestFn } from '@wix/yoshi-flow-editor';
import { buildAdaptiveContentAdapter } from './adapter/adaptiveContent/adaptiveContent.adapter';
import { buildAdaptiveContentService } from './services/adaptiveContent/adaptiveContent.service';
import {
  ADAPTIVE_CONTENT_WIDGET_ID,
  ADAPTIVE_CONTENT_WIDGET_OPTIONS,
  APP_WIDGET_PHOTO_URL,
} from './constants';
import { buildEditorService } from './services/editor/editor.service';
import { AppManifest } from '@wix/platform-editor-sdk';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefId,
  options,
  flowAPI,
) => {
  const { httpClient } = flowAPI;
  const adaptiveContentAdapter = buildAdaptiveContentAdapter(httpClient);
  const adaptiveContentService = buildAdaptiveContentService(
    adaptiveContentAdapter,
  );
  const editorService = buildEditorService(editorSDK);

  if (options.firstInstall) {
    await editorService.addComponent(ADAPTIVE_CONTENT_WIDGET_OPTIONS);
  }

  await editorSDK.addEventListener('siteWasPublished', async () => {
    return adaptiveContentService.triggerFunctionCleanup();
  });
};

export const getAppManifest: GetAppManifestFn = async (
  options,
  editorSDK,
  contextParams,
  flowAPI,
): Promise<AppManifest> => {
  const { appManifestBuilder } = options;
  const editorService = buildEditorService(editorSDK);
  const { t: translate } = flowAPI.translations;

  const manifest = appManifestBuilder
    .configureManagementActions((managementActionsBuilder) => {
      managementActionsBuilder.mainActions().addAction({
        title: translate('Add Text Component'),
        icon: 'addAction',
        onClick: async () => {
          await editorService.addComponent(ADAPTIVE_CONTENT_WIDGET_OPTIONS);
        },
      });
    })
    .build();

  // @ts-expect-error
  manifest.addPanelSectionDefinition = {
    type: 'LIST',
    title: translate('Adaptive Content'),
    header: translate('Adaptive Content Components'),
    items: [
      {
        id: 'adaptive-content-widget',
        title: translate('Adaptive Text'),
        desc: translate('Add adaptive content to your site'),
        image: APP_WIDGET_PHOTO_URL,
        widgetId: ADAPTIVE_CONTENT_WIDGET_ID,
        presetId: 'variants-lgnn37aw',
        size: {
          height: 360,
          width: 480,
        },
      },
    ],
  };

  return manifest;
};
