import {
  addWidgetOptions,
  ComponentRef,
  EditorSDK,
} from '@wix/platform-editor-sdk';
import { IEditorService } from './types';
import { ADAPTIVE_CONTENT_APP_DEF_ID, TOKEN } from '../../constants';
import { extractParagraphText } from '../../components/AdaptiveContentWidget/utils';

class EditorService implements IEditorService {
  constructor(private editorSDK: EditorSDK) {}

  async getSelectedComponentText() {
    const selectedComponent = await this.getFirstSelectedComponent();
    if (!selectedComponent) {
      return '';
    }

    const childrenData = await this.getComponentChildren(selectedComponent);
    const textComponent = this.findTextComponent(childrenData);

    if (!textComponent || !textComponent.text) {
      return '';
    }

    try {
      return extractParagraphText(textComponent.text);
    } catch (error) {
      console.error('Error extracting text:', error);
      return '';
    }
  }

  async addComponent(addWidget: addWidgetOptions): Promise<void> {
    const { check, show } = this.editorSDK.editor.deeplink;
    const addPanel = {
      type: 'addPanel' as const,
      params: [ADAPTIVE_CONTENT_APP_DEF_ID],
    };

    const editorDeepLinkToBookingsAddPanel = await check(TOKEN, addPanel);

    editorDeepLinkToBookingsAddPanel && (await show(TOKEN, addPanel));
  }

  async getFirstSelectedComponent() {
    const comps = await this.editorSDK.selection.getSelectedComponents(TOKEN);
    return comps[0];
  }

  async openBusinessManager(url: string) {
    await this.editorSDK.editor.openDashboardPanel(TOKEN, {
      url,
      closeOtherPanels: true,
    });
  }

  async showError() {
    return this.editorSDK.editor.openErrorPanel(TOKEN, {
      headerText: 'Something went wrong',
      topDescriptionText:
        'We were unable to get the settings for this component. Please try again.',
      shouldShowIllustration: false,
      mainActionText: 'Close',
      secondaryActionText: '',
    });
  }

  private async getComponentChildren(componentRef: ComponentRef) {
    const children = await this.editorSDK.document.components.getChildren(
      TOKEN,
      {
        componentRef,
        recursive: true,
      },
    );

    return Promise.all(
      children.map(async (child) =>
        this.editorSDK.components.data.get(TOKEN, {
          componentRef: child,
        }),
      ),
    );
  }

  private findTextComponent(childrenData: any[]) {
    return childrenData.find(
      (child) =>
        child &&
        typeof child === 'object' &&
        (child as any).type === 'StyledText',
    ) as { text: string } | undefined;
  }
}

export const buildEditorService = (editorSDK: EditorSDK) => {
  return new EditorService(editorSDK);
};
